// CSS Imports *include in entry file only
import '../scss/app.scss';

/* Boostrap 4 Full Bundles */
// import 'bootstrap/dist/js/bootstrap.bundle'; /* Includes popper.js */
// import 'bootstrap'; /* Does not include popper. js */
import $ from 'jquery';
import datepicker from 'js-datepicker';

import moment from 'moment';
/* Boostrap 4 Individual Components (Optional) */
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown'; /* requires popper.js - Uncomment 'popper.js' in webpack.config.js */
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/tooltip'; /* requires popper.js - Uncomment 'popper.js' in webpack.config.js */
// import 'bootstrap/js/dist/util';

import objectFitImages from 'object-fit-images';

//import './fad/script.js';

objectFitImages();

// set length of text for description
var length = 150;

var events = [];
var maxDate = false;
var minDate = false;
// var startDate, endDate;

var maxDateCal = new Date(2099, 1, 1);
var minDateCal = new Date(2016, 1, 1);

if (document.querySelector('#startDate')) {
    datepicker('#startDate', {
        id: 1,
        maxDate: maxDateCal,
        onSelect: function(instance, date) {
            if (date) {
                minDate = moment(date, 'MM/DD/YYYY');
                minDateCal = minDate.toDate();
            } else {
                minDate = false;
                minDateCal = new Date(2016, 1, 1);
            }
            filter(events);
        },
        formatter: function(input, date, instance) {
            input.value = date.toLocaleDateString();
        }
    });
}
if (document.querySelector('#endDate')) {
    datepicker('#endDate', {
        id: 1,
        onSelect: function(instance, date) {
            if (date) {
                maxDate = moment(date, 'MM/DD/YYYY');
                maxDateCal = maxDate.toDate();
            } else {
                maxDate = false;
                maxDateCal = new Date(2099, 0, 1);
            }
            filter(events);
        },
        formatter: function(input, date, instance) {
            input.value = date.toLocaleDateString();
        },
        minDate: minDateCal
    });
}

export function filter(events) {
    var pastEvents = [];
    var newEvents = [];

    if (maxDate) {
        events = events.filter(function(el) {
            return moment(el.EventStart) <= maxDate;
        });
    }

    if (minDate) {
        events = events.filter(function(el) {
            return moment(el.EventStart) >= minDate;
        });
    }

    newEvents = events.sort(function(a, b) {
        return moment(a.EventStart) - moment(b.EventStart);
    });
    newEvents = newEvents.filter(function(el) {
        return moment(el.EventStart) >= moment(moment().toDate());
    });

    pastEvents = events.sort(function(a, b) {
        return moment(b.EventStart) - moment(a.EventStart);
    });
    pastEvents = pastEvents.filter(function(el) {
        return moment(el.EventStart) < moment(moment().toDate());
    });

    var pastEventsView = [];
    var newEventsView = [];
    var topNewEventsView = [];

    $.each(newEvents, function(key, event) {
        //if (event && event.ImageURL) 
        {
            var string = event.TileDescription;
            event.TileDescription = string.length > length ?
                string.substring(0, length - 3) + '...' :
                string;

            if (topNewEventsView.length < 3) {
// use <a href="#"><img class="card-img-top" style="height:220px; background-size: 100% 100%;" if fixed height is required
                topNewEventsView.push('' +
                    '    <div class="col-md-4 on-hover">' +
                    '        <div class="card border-0 mb-4" >' +
                    '            <a href="#"><img class="card-img-top"  src="' + event.ImageURL + '"' +
                    '                             alt="wrappixel kit"></a>' +
                    '            <div class="date-pos bg-info-gradiant p-2 d-inline-block text-center rounded text-white position-absolute">' + moment(event.EventStart).format('MMM') +
                    '<span class="d-block">' + moment(event.EventStart).format('D') + '</span></div>' +
                    '            <div class="card-body">' +
                    '                <h5 class="font-weight-medium"><a href="#" class="text-decoration-none link">' + event.Title + '</a></h5>' +
                    '                <p class="mt-3">' + event.TileDescription + '</p>' +
                    '            </div>' +
                    '        </div>' +
                    '    </div>');
            } else {
                newEventsView.push('<li>' +
                    '    <time datetime="' + event.EventStart + '">' +
                    '        <span class="month">' + moment(event.EventStart).format('MMM') + '</span><span class="day">' + moment(event.EventStart).format('D') + '</span>' +
                    '        <span class="year">' + moment(event.EventStart).format('YYYY') + '</span>' +
                    '        <span class="time"></span>' +
                    '    </time>' +
                    '    <img alt="' + event.Title + '" src="' + event.ImageURL + '"/>' +
                    '    <div class="social">' +
                    '        <ul>' +
                    '            <li class="facebook" style="width:33%;"><a href="#facebook"><span' +
                    '                    class="fa fa-facebook"></span></a></li>' +
                    '            <li class="twitter" style="width:34%;"><a href="#twitter"><span' +
                    '                    class="fa fa-twitter"></span></a></li>' +
                    '            <li class="google-plus" style="width:33%;"><a href="#google-plus"><span' +
                    '                    class="fa fa-google-plus"></span></a></li>' +
                    '        </ul>' +
                    '    </div>' +
                    '    <div class="info" style="margin-right: 1%;">' +
                    '        <h2 class="title">' + event.Title + '</h2>' +
                    '        <p class="desc">' + event.TileDescription + '</p>' +
                    '        <p class="desc desc-links">' +
                    '            <a href="#" class="card-link text-danger">Meeting' +
                    '                Information</a>' +
                    '            <a href="#" class="card-link text-danger">Registration' +
                    '                Link</a>' +
                    // '            <a href="#" class="card-link text-danger">Hotel' +
                    // '                Reservations</a>' +
                    '        </p>' +
                    '    </div>' +
                    '' +
                    '</li>');
            }
        }
    });
    $.each(pastEvents, function(key, event) {
        // check if event has cover url
        //if (event &&  event.ImageURL) 
        {
            var string = event.TileDescription;
            event.TileDescription = string.length > length ?
                string.substring(0, length - 3) + '...' :
                string;

            pastEventsView.push('<li>' +
                '    <time datetime="' + event.EventStart + '">' +
                '        <span class="month">' + moment(event.EventStart).format('MMM') + '</span><span class="day">' + moment(event.EventStart).format('D') + '</span>' +
                '        <span class="year">' + moment(event.EventStart).format('YYYY') + '</span>' +
                '        <span class="time"></span>' +
                '    </time>' +
                '    <img alt="' + event.Title + '" src="' + event.ImageURL + '"/>' +
                '    <div class="info">' +
                '        <h2 class="title">' + event.Title + '</h2>' +
                '        <p class="desc">' + event.TileDescription + '</p>' +
                '    </div>' +
                '    <div class="social">' +
                '        <ul class="">' +
                '            <li class="facebook"><a href="#facebook"><span class="fa fa-facebook"></span></a></li>' +
                '            <li class="twitter"><a href="#twitter"><span class="fa fa-twitter"></span></a></li>' +
                '            <li class="google-plus"><a href="#google-plus"><span' +
                '                    class="fa fa-google-plus"></span></a></li>' +
                '        </ul>' +
                '    </div>' +
                '</li>');
        }
    });

    // clear old events blocks
    $('.upcoming-events, .past-events, .next-events').html('');

    // create new elements with events
    $('<ul/>', {
        class: 'event-list',
        html: pastEventsView.join('')
    }).appendTo('.past-events');

    $('<ul/>', {
        class: 'event-list',
        html: newEventsView.join('')
    }).appendTo('.next-events');

    $('<div/>', {
        class: 'row mt-4',
        html: topNewEventsView.join('')
    }).appendTo('.upcoming-events');
};

$(document).ready(function() {
    // home page code

    if ($('.js-home-page').length) {
        var items = [];
        var indicators = [];
        var activeClass =
            $.getJSON('https://dev.ag-risk.org/api/default/newsitems', function(data) {

                        // console.log(data.events);
                        $.each(data.value, function(key, val) {
                            // items.push('<li id=\'' + key + '\'>' + val.ImageURL + '</li>');

                            // if (items.length > 5) {
                            //  return;
                            // }

                            // if (val && val.cover && val.ImageURL) 
                            {
                                if (key === 0) {
                                    activeClass = 'active';
                                } else {
                                    activeClass = '';
                                }
                                items.push('<div class="carousel-item ' + activeClass + '"><img src="' + val.ImageURL + '" class="d-block w-100" alt="">' +
                                    '<div id= "carouselItem" class="carousel-caption d-md-block" style="position: absolute;   top: 0px;  left: 0px;  width:calc(100% - 48px); margin-top: 48px; margin-bottom: 24px; margin-left: 24px; margin-right: 24px; overflow: auto; color:none !important; padding: 0;">' +
                                    val.Content + 
                                    '</div>' +
                                    '</div>');

                                indicators.push('<li data-target="#carouselCardCaptions" data-slide-to="' + key + '" class="' + activeClass + '"></li>');
                            }
							// return false;

                        });
                        $('<div/>', {
                            class: 'carousel-inner',
                            html: items.join('')
                        }).appendTo('.carousel');

                        $('<ol/>', {
                            class: 'carousel-indicators',
							'id': 'carouselIndicators',
                            html: indicators.join('')
                        }).appendTo('.carousel');

                        $('.carousel').carousel();
						if (data.value.length == 1) 
						{
						
												$("#dataSildePrev").hide();
												$("#dataSildeNext").hide();
												$("#carouselItem").css({ 'width': 'calc(100% - 32px)' });
												$("#carouselItem").css("margin-left", "16px");
												$("#carouselItem").css("margin-right", "16px");
												$("#carouselItem").css("margin-bottom", "0px");
												$("#carouselIndicators").removeClass("carousel-indicators");
						}
						
                   
                }

            );
    } else if ($('.js-events-page').length) { //event page code
        // datepicker
        // createDatepicker();
        $.getJSON('https://dev.ag-risk.org/api/default/events?orderby=EventStart%20desc', function(data) {
            events = data.value;
            filter(events);
        });
    }
	
	
});

/* If in development mode, watch for changes to html files */
if (process.env.NODE_ENV === 'development') {
  const glob = require('glob');
  const path = require('path');

  require('../index.html');
  glob.sync('./templates/!**!/!*.js').forEach(function (file) {
    require(path.resolve(file));
  });
}